import React from 'react';
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from 'react-router-dom';
import { Security } from '@okta/okta-react';
import { App } from "./App.js";
import config from "./config.js";

import './App.css';

ReactDOM.render(
    <Router>
        <Security {...config.oidc}>
            <App />
        </Security>
    </Router>,
    document.getElementById('root')
);

if (module.hot) module.hot.accept();
