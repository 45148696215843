import React, { useState, useEffect } from 'react';
import { withOktaAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import { userService } from '../services/user.service';
import { Link } from 'react-router-dom';

async function checkUser() {
    if (this.props.authState.isAuthenticated && !this.state.userInfo) {
        const userInfo = await this.props.authService.getUser();
        const token = await this.props.authState.accessToken;
        if (this._isMounted) {
            this.state = ({ userInfo, token: token });
        }
    }
}

const SecureUserList = withOktaAuth(
    class UserList extends React.Component {
        constructor(props) {
            super(props);
            this.path = props.match.path;
            this.state = { users: [], page: 0, size: 20, userInfo: null };
            this.loadAll = this.loadAll.bind(this);
            this.handleDelete = this.handleDelete.bind(this);
            this.handlePagination = this.handlePagination.bind(this);
            this.checkUser = checkUser.bind(this);
        }

        async componentDidMount() {
            this._isMounted = true;
            try {
                this.loadAll(0, 20);
            }
            catch (err) {
                alert(err);
            }
        }

        async componentDidUpdate() {
            // withOktaAuth is changing state which fires this method
            this._isMounted = true;
        }

        componentWillUnmount() {
            this._isMounted = false;
        }

        loadAll(page, size) {
            console.log("loadAll: have token? " + (this.props.authState.accessToken ? "yes" : "no"));
            if (this.props.authState.accessToken) {
                userService.getAll(this.props.authState.accessToken, page, size)
                    .then(response => {
                        this.setState({
                            page: page,
                            size: size,
                            users: response.entity
                        });
                        console.log("loadAll: got " + this.state.users.length + " users");
                    })
                    .catch(response => {
                        console.error("Error getting list:" + response.error);
                        this.props.alert.error("Error " + response.error);
                    });
            }
            else {
                console.log("loadAll: no token");
            }
        }

        handlePagination(page, size) {
            console.log("handlePagination:" + JSON.stringify(this.state));
            this.loadAll(page, size);
        }

        handleDelete(id) {
            console.log("handleDelete: " + id);
            userService.remove(this.props.authState.accessToken, { 'id': id })
                .then(response => {
                    this.loadAll(this.state.page, this.state.size);
                });
        }

        render() {
            console.log("user list render with size: " + (this.state.users.length));
            const page = this.state.page;
            const size = this.state.size;

            let previous = "";
            if (page > 0) {
                previous =
                    <li class="page-user">
                        <a class="page-link nav-link" onClick={() => this.handlePagination(page - 1, size)}><span aria-hidden="true">&laquo;</span></a>
                    </li>
            }

            let next = "";
            if (this.state.users.size > 0) {
                next =
                    <li class="page-user">
                        <a class="page-link nav-link" onClick={() => this.handlePagination(page + 1, size)}><span aria-hidden="true">&raquo;</span></a>
                    </li>
            }

            return (
                <div>
                    <table class="table table-hover">
                        <thead class="thead-dark">
                            <tr>
                                <th scope="col">Last Name</th>
                                <th scope="col">First Name</th>
                                <th scope="col">Email</th>
                                <th scope="col">Active</th>
                                <th scope="col">Created</th>
                                <th scope="col">Update</th>
                                <th scope="col">Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.users.map(user =>
                                <User key={user.id} user={user} onDelete={this.handleDelete} path={this.path} />
                            )}
                        </tbody>
                    </table>
                    <nav aria-label="Page navigation">
                        <ul class="pagination justify-content-end">
                            {previous}
                            <li class="page-user">
                                <a class="page-link" onClick={() => this.handlePagination(page, size)}>{page + 1}</a>
                            </li>
                            {next}
                        </ul>
                    </nav>
                    <div>
                        <Link to={`${this.path}/add`} class="btn btn-primary">Create</Link>
                    </div>
                </div>
            );
        }
    }
)

class User extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <tr>
                <td scope="row">{this.props.user.firstName}</td>
                <td>{this.props.user.lastName}</td>
                <td>{this.props.user.email}</td>
                <td>{(this.props.user.active ? "Yes" : "")}</td>
                <td>{this.props.user.created}</td>
                <td>
                    <Link to={`${this.props.path}/edit/${this.props.user.id}`} class="btn btn-primary">Update</Link>
                </td>
                <td>
                    <button class="btn btn-primary" onClick={() => this.props.onDelete(this.props.user.id)}>Delete</button>
                </td>
            </tr>
        );
    }
}

//export { SecureUserList, User };
export default withAlert()(SecureUserList);
