import React from 'react';

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div class="card-deck">
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title">Total Users</h5>
                        <p class="card-body">15</p>
                    </div>
                </div>
            </div>
        );
    }

}

export { Dashboard };
