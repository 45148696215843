import React, { useState, useEffect } from 'react';
import { withOktaAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import { itemService } from '../services/item.service';
import { Link } from 'react-router-dom';


async function checkUser() {
    //console.log("checking user...");
    if (this.props.authState.isAuthenticated && !this.state.userInfo) {
        const userInfo = await this.props.authService.getUser();
        const token = await this.props.authState.accessToken;
        if (this._isMounted) {
            //console.log("checkUser: got userinfo=" + JSON.stringify(userInfo));
            this.state = ({ userInfo, token: token });
        }
    }
}

const SecureItemList = withOktaAuth(
    class ItemList extends React.Component {
        constructor(props) {
            super(props);
            this.path = props.match.path;
            this.state = { items: [], page: 0, size: 20, userInfo: null };
            this.loadAll = this.loadAll.bind(this);
            this.handleDelete = this.handleDelete.bind(this);
            this.handlePagination = this.handlePagination.bind(this);
            this.checkUser = checkUser.bind(this);
        }

        async componentDidMount() {
            this._isMounted = true;
            this.loadAll(0, 20);
        }

        async componentDidUpdate() {
            // withOktaAuth is changing state which fires this method
            this._isMounted = true;
        }

        componentWillUnmount() {
            this._isMounted = false;
        }

        async loadAll(page, size) {
            //console.log("loadAll: have token? " + (this.props.authState.accessToken ? "yes" : "no"));
            if (this.props.authState.accessToken) {
                const userInfo = await this.props.authService.getUser();
                //console.log("loadAll: userinfo=" + JSON.stringify(userInfo));
                itemService.getAllCount(this.props.authState.accessToken).then(countResponse => {
                    //console.log("count=" + JSON.stringify(countResponse));

                    itemService.getAll(this.props.authState.accessToken, page, size)
                        .then(response => {
                            this.setState({
                                page: page,
                                size: size,
                                count: countResponse.entity,
                                items: response.entity,
                                userInfo
                            });
                            //console.log("loadAll: got " + this.state.items.length + " inv items");
                        })
                        .catch(response => {
                            console.error("Error getting list:" + response.error);
                            this.props.alert.error("Error getting items " + response.error);
                        });
                    });
            }
            else {
                //console.log("loadAll: no token");
            }
        }

        handlePagination(page, size) {
            //console.log("handlePagination:" + JSON.stringify(this.state));
            this.loadAll(page, size);
        }

        handleDelete(id) {
            //console.log("handleDelete: " + id);
            itemService.remove(this.props.authState.accessToken, { 'id': id })
                .then(response => {
                    this.props.alert.success("Item deleted.");
                    this.loadAll(this.state.page, this.state.size);
                })
                .catch(error => {
                    this.props.alert.error("Error deleting item " + error.error);
                });    
        }

        render() {
            //console.log("inv list render with size: " + (this.state.items.length));
            const page = this.state.page;
            const size = this.state.size;
            const pageEnd = (page + 1) * size;

            let previous = "";
            if (page > 0) {
                previous =
                    <li class="page-item">
                        <a class="page-link nav-link" onClick={() => this.handlePagination(page - 1, size)}><span aria-hidden="true">&laquo;</span></a>
                    </li>
            }

            let next = "";
            if (this.state.count > pageEnd) {
                next =
                    <li class="page-item">
                        <a class="page-link nav-link" onClick={() => this.handlePagination(page + 1, size)}><span aria-hidden="true">&raquo;</span></a>
                    </li>
            }

            return (
                <div>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="card card-table">
                                <div class="card-body">
                                    <table class="table table-striped table-hover table-fw-widget">
                                        <thead>
                                            <tr>
                                                <th scope="col">Name</th>
                                                <th scope="col">Container</th>
                                                <th scope="col">Ounces</th>
                                                <th scope="col">Calories</th>
                                                <th scope="col">Fat</th>
                                                <th scope="col">Carbs</th>
                                                <th scope="col">Protein</th>
                                                <th scope="col">UPC</th>
                                                <th scope="col">FDC ID</th>
                                                <th scope="col">Update</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.items.map(item =>
                                                <Item key={item.id} item={item} onDelete={this.handleDelete} path={this.path} email={this.state.userInfo.email} />
                                            )}
                                        </tbody>
                                    </table>
                                    </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-12">
                            <nav aria-label="Page navigation">
                                <ul class="pagination justify-content-end">
                                    {previous}
                                    <li class="page-item">
                                        <a class="page-link" onClick={() => this.handlePagination(page, size)}>{page + 1}</a>
                                    </li>
                                    {next}
                                </ul>
                            </nav>
                            <div>
                                <Link to={`${this.path}/add`} class="btn btn-primary">Create</Link>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
)

class Item extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <tr>
                <td scope="row">{this.props.item.name}</td>
                <td>{this.props.item.container}</td>
                <td>{this.props.item.weight}</td>
                <td>{this.props.item.calories}</td>
                <td>{this.props.item.fat}</td>
                <td>{this.props.item.carbs}</td>
                <td>{this.props.item.protein}</td>
                <td>{this.props.item.upc}</td>
                <td>{this.props.item.fdcid}</td>
                <td>
                    <Link to={`${this.props.path}/edit/${this.props.item.id}`} class="btn btn-primary">Update</Link>
                </td>
                <td>
                    <button class="btn btn-primary" onClick={() => this.props.onDelete(this.props.item.id)}>Delete</button>
                </td>
            </tr>
        );
    }
}

export default withAlert()(SecureItemList);
