import React from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { SecureRoute, LoginCallback } from '@okta/okta-react';
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'
import { Navbar } from "./Navbar";
import { Navmenu } from "./Navmenu";
import { Dashboard } from "./dashboard";
import { Item } from "./item";
import { User } from "./user";
import { Static } from "./static";

const alertOptions = {
    position: positions.BOTTOM_CENTER,
    timeout: 10000,
    offset: '30px',
    transition: transitions.SCALE
}

const App = () => {
    return (
        <AlertProvider template={AlertTemplate} {...alertOptions}>
            <Navbar />
            <Navmenu />
            <div class="be-content">
                <div class="main-content container-fluid">
                    <div>
                        <Switch>
                            <SecureRoute exact path='/dashboard' component={Dashboard} />
                            <SecureRoute path='/item' component={Item} />
                            <SecureRoute path='/user' component={User} />
                            <Route path='/static' component={Static} />
                            <Route path='/login/callback' component={LoginCallback} />
                            <Redirect from="*" to="/" />
                        </Switch>
                    </div>
                </div>
            </div>
        </AlertProvider>
    )
};

export { App };
