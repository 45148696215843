import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Privacy from './Privacy';
import Tos from './Tos';
import About from './About';

function Static({ match }) {
    const { path } = match;

    console.log("Static - hello");

    return (
        <Switch>
            <Route path={`${path}/tos`} component={Tos} />
            <Route path={`${path}/about`} component={About} />
            <Route path={`${path}/privacy`} component={Privacy} />
        </Switch>
    );
}

export { Static };
