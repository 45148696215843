import React from 'react';

function About({ match }) {
    const { path } = match;

    return (
        <>
            <h3>About Us</h3>
            <div>
                Coming soon...
            </div>
        </>
    );
}

export default About;
