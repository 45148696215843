import React from 'react';

function Tos({ match }) {
    const { path } = match;

    return (
        <>
            <h3>Terms of Service</h3>
            <div>
                Formal terms coming soon but in the meantime don't do anything stupid.
            </div>
        </>
    );
}

export default Tos;
