import React from 'react';

function Privacy({ match }) {
    const { path } = match;

    return (
        <>
            <h3>Privacy Policy</h3>
            <div>
                Coming soon...
            </div>
        </>
    );
}

export default Privacy;
