import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

import SecureUserList from './List';
import AddEdit from './AddEdit';

function User({ match }) {
    let query = new URLSearchParams(useLocation().search);
    //console.log("inv index location=" + JSON.stringify(location));
    const { path } = match;

    return (
        <Switch>
            <Route exact path={path} component={SecureUserList} />
            <Route path={`${path}/add`} component={AddEdit} />
            <Route path={`${path}/edit/:id`} component={AddEdit} />
        </Switch>
    );
}

export { User };
