import React, { useState, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { userService } from '../services/user.service';
import { Formik, Field, Form, ErrorMessage, useFormikContext } from 'formik';
import { useAlert } from 'react-alert';


function AddEdit({ history, match }) {
    const { authState } = useOktaAuth();
    const alert = useAlert();
    const { id } = match.params;
    const isAddMode = !id;

    const initialValues = {
        firstName: '',
        lastName: '',
        email: '',
        created: '',
        active: false,
        uid: ''
    }

    function handleSubmit(fields, { setStatus, setSubmitting }) {
        setStatus();
        if (isAddMode) {
            createUser(fields, setSubmitting);
        }
        else {
            updateUser(id, fields, setSubmitting);
        }
    }

    function createUser(fields, setSubmitting) {
        userService.create(authState.accessToken, fields)
            .then(() => {
                console.log("created user, so load all");
                history.push('.');
            })
            .catch(error => {
                setSubmitting(false);
                alert.error("Error " + error.error);
            });
    }

    function updateUser(id, fields, setSubmitting) {
        const updated = {
            "id": id,
            ...fields
        };
        userService.update(authState.accessToken, updated)
            .then(() => {
                console.log("updated user, so load all");
                history.push('..');
            })
            .catch(error => {
                setSubmitting(false);
                alert.error("Error " + error.error);
            });
    }

    return (
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            {({ errors, touched, isSubmitting, setFieldValue }) => {
                const [user, setUser] = useState({});

                useEffect(() => {
                    if (authState.isAuthenticated) {
                        if (!isAddMode) {
                            userService.getById(authState.accessToken, id).then(response => {
                                const fields = ['firstName', 'lastName', 'email', 'created', 'active', 'uid'];
                                const user = response.entity;
                                fields.forEach(field => setFieldValue(field, user[field], false));
                                setUser(user);
                            });
                        }
                    }
                }, [authState]);

                return (
                    <Form>
                        <h1>{isAddMode ? 'Add User' : 'Edit User'}</h1>
                        <div class="form-group">
                            <label for="firstName">First Name</label>
                            <Field type="input" class="form-control" id="firstName" name="firstName" aria-describedby="firstNameHelp" />
                        </div>
                        <div class="form-group">
                            <label for="lastName">Last Name</label>
                            <Field type="input" class="form-control" id="lastName" name="lastName" aria-describedby="firstNameHelp" />
                        </div>
                        <div class="form-group">
                            <label for="email">Email</label>
                            <Field type="input" class="form-control" id="email" name="email" aria-describedby="firstNameHelp" />
                        </div>
                        <div class="form-group">
                            <label for="active">
                                <Field type="checkbox" id="active" name="active" aria-describedby="activeHelp" />
                                {`${user.active}`}
                            </label>
                        </div>
                        <div class="form-group">
                            <label for="created">Created</label>
                            <Field type="input" class="form-control" id="created" name="created" aria-describedby="createdHelp" />
                        </div>
                        <div class="form-group">
                            <button type="submit" class="btn btn-primary">Save</button>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
}

export default AddEdit;
