import Config from 'Config';

console.log("config=" + JSON.stringify(Config));

const CLIENT_ID = process.env.CLIENT_ID || '0oa1h1la3fTh5iKR94x7';
const ISSUER = process.env.ISSUER || 'https://login.prepninja.app/oauth2/default';
const OKTA_TESTING_DISABLEHTTPSCHECK = process.env.OKTA_TESTING_DISABLEHTTPSCHECK || false;
const API_HOST = Config.API_HOST || "http://adminapi.prepninja.app";
const REDIRECT_URI = Config.REDIRECT_URI || "http://admin.prepninja.app/login/callback";
const LOGOUT_REDIRECT_URI = Config.LOGOUT_REDIRECT_URI || "https://admin.prepninja.app";

export default {
    oidc: {
        clientId: CLIENT_ID,
        issuer: ISSUER,
        redirectUri: REDIRECT_URI,
        scopes: ['openid', 'profile', 'email'],
        pkce: true,
        disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
        postLogoutRedirectUri: LOGOUT_REDIRECT_URI
    },
    api: {
        uri: API_HOST + "/adminapi"
    }
};
