import React, { useState, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useAlert } from 'react-alert';
import { itemService } from '../services/item.service';
import { foodApiService } from '../services/foodapi.service';
import { Formik, Field, Form, ErrorMessage, useFormikContext, useField } from 'formik';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';


function AddEdit({ history, match }) {
    const alert = useAlert();
    const { authState } = useOktaAuth();
    const { id } = match.params;
    const isAddMode = !id;

    const initialValues = {
        name: '',
        container: '',
        weight: 0.0,
        units: 'ounces',
        calories: 0,
        fat: 0,
        carbs: 0,
        protein: 0,
        upc: '',
        fdcid: ''
    }

    function handleSubmit(fields, { setStatus, setSubmitting }) {
        setStatus();
        if (isAddMode) {
            createItem(fields, setSubmitting);
        }
        else {
            updateItem(id, fields, setSubmitting);
        }
    }

    function createItem(fields, setSubmitting) {
        itemService.create(authState.accessToken, fields)
            .then(() => {
                //console.log("created item, so load all");
                alert.success("Item saved.");
                history.push('.');
            })
            .catch(error => {
                setSubmitting(false);
                alert.error("Error saving item " + error.error);
            });
    }

    function updateItem(id, fields, setSubmitting) {
        const updated = {
            "id": id,
            ...fields
        };
        itemService.update(authState.accessToken, updated)
            .then(() => {
                //console.log("updated item, so load all");
                alert.success("Item saved.");
                history.push('..');
            })
            .catch(error => {
                setSubmitting(false);
                alert.error("Error saving item " + error.error);
            });
    }

    const ScaledField = (props) => {
        // DEO need to keep track of original values
        const {
            values: { weight },
            touched,
            setFieldValue
        } = useFormikContext();
        const [field, meta] = useField(props);

        useEffect(() => {
            console.log("field=" + JSON.stringify(field));
            console.log("scaling " + props.name + " " + weight + " * " + field.value);
            if (!isNaN(weight) && !isNaN(field.value) && touched.calories) {
                setFieldValue(props.name, weight * field.value);
            }
        }, [weight, touched.calories, setFieldValue, props.name]);

        return (
            <>
                <input {...props} {...field} />
                {!!meta.error && <div>{meta.error}</div>}
            </>
        );
    }

    const SearchButton = ({ handleClick }) => (
        <button type="button" class="btn btn-info" onClick={handleClick}>Search FDA database</button>
    );

    const fieldName = "search";

    const SearchInput = () => {
        const { values, setFieldValue } = useFormikContext();
        const [searchResults, setSearchResults] = useState([]);
        const [show, setShow] = useState(false);

        const handleClose = () => setShow(false);

        const handleShow = () => setShow(true);

        function loadDetails(id) {
            //console.log("get details for " + id);
            foodApiService.details(authState.accessToken, id).then(response => {
                const details = response.entity[0];
                setFieldValue("name", details.description);
                setFieldValue("fdcid", details.fdcId);
                setFieldValue("upc", details.gtinUpc);
                if (details.labelNutrients) {
                    setFieldValue("calories", details.labelNutrients.calories.value);
                    setFieldValue("fat", details.labelNutrients.fat.value);
                    setFieldValue("carbs", details.labelNutrients.carbohydrates.value);
                    setFieldValue("protein", details.labelNutrients.protein.value);
                }

                setShow(false);
            });
        }

        function searchFoodApi(searchTerm) {
            //console.log("searching food api for " + searchTerm);
            foodApiService.search(authState.accessToken, searchTerm)
                .then(response => {
                    //console.log("totalHits=" + response.entity.totalHits);
                    //console.log("food.len=" + response.entity.foods.length);
                    let searchResults = [];
                    response.entity.foods.forEach(result => {
                        searchResults.push(
                            <ListGroup.Item key={result.fdcid} action onClick={() => loadDetails(result.fdcId)}>
                                <span class="small">{result.description}</span>
                            </ListGroup.Item>
                        );
                    });

                    // show modal
                    setSearchResults(searchResults);
                    setShow(true);
                });
        }

        return (
            <Field name={fieldName} id={fieldName}>
                {({ field: { value }, form: { setFieldvalue } }) => (
                    <>
                        <SearchButton handleClick={val => searchFoodApi(values.name)} />
                        <Modal show={show} onHide={handleClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>Search Results</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <ListGroup onClick={handleClose}>
                                    {searchResults}
                                </ListGroup>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    Close
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </>
                )}
            </Field>
        );
    }

    return (
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            {({ errors, touched, isSubmitting, setFieldValue }) => {
                const [item, setItem] = useState({});

                useEffect(() => {
                    if (authState.isAuthenticated) {
                        if (!isAddMode) {
                            itemService.getById(authState.accessToken, id).then(response => {
                                const fields = ['name', 'container', 'weight', 'units', 'calories', 'fat', 'carbs', 'protein', 'upc', 'fdcid'];
                                const item = response.entity;
                                fields.forEach(field => setFieldValue(field, item[field], false));
                                setItem(item);
                            });
                        }
                    }
                }, [authState]);

                return (
                    <Form>
                        <h1>{isAddMode ? 'Add Item' : 'Edit Item'}</h1>
                        <div class="form-group">
                            <label for="createName">Name</label>
                            <Field type="input" class="form-control" id="name" name="name" aria-describedby="nameHelp" />
                        </div>
                        <div class="form-group">
                            <SearchInput />
                        </div>
                        <div class="form-group">
                            <label for="createContainer">Container</label>
                            <Field type="input" as="select" class="form-control" id="container" name="container" aria-describedby="containerHelp">
                                <option />
                                <option>Bag</option>
                                <option>Bottle</option>
                                <option>Box</option>
                                <option>Can</option>
                                <option>Carton</option>
                                <option>Jar</option>
                                <option>Jug</option>
                                <option>Other</option>
                            </Field>
                        </div>
                        <div class="form-group">
                            <label for="createOunces">Ounces</label>
                            <Field type="input" class="form-control" id="weight" name="weight" aria-describedby="weightHelp" />
                        </div>
                        <div class="form-group">
                            <label for="createCalories">Calories</label>
                            <Field type="input" class="form-control" id="calories" name="calories" aria-describedby="caloriesHelp" />
                        </div>
                        <div class="form-group">
                            <label for="createFat">Fat</label>
                            <Field type="input" class="form-control" id="fat" name="fat" aria-describedby="fatHelp" />
                        </div>
                        <div class="form-group">
                            <label for="createCarbs">Carbs</label>
                            <Field type="input" class="form-control" id="carbs" name="carbs" aria-describedby="carbsHelp" />
                        </div>
                        <div class="form-group">
                            <label for="createProtein">Protein</label>
                            <Field type="input" class="form-control" id="protein" name="protein" aria-describedby="proteinHelp" />
                        </div>
                        <div class="form-group">
                            <label for="createProtein">UPC</label>
                            <Field type="input" class="form-control" id="upc" name="upc" aria-describedby="proteinHelp" />
                        </div>
                        <div class="form-group">
                            <label for="createProtein">FDC id</label>
                            <Field type="input" class="form-control" id="fdcid" name="fdcid" aria-describedby="proteinHelp" />
                        </div>
                        <div class="form-group">
                            <button type="submit" class="btn btn-primary">Save</button>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
}

export default AddEdit;
