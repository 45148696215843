import client from "../client"
import config from "../config.js";

const APIURLBASE = config.api.uri + "/items";

export const itemService = {
    getAll,
    getAllCount,
    getById,
    create,
    update,
    remove,
    searchByName
};

function searchByName(token, query, page, size) {
    return client({
        method: 'GET', path: APIURLBASE + '/search/' + query + '?page=' + (page ? page : 0) + '&size=' + (size ? size : 20),
        headers: { 'Authorization': 'Bearer ' + token }
    });
}

function getAll(token, page, size) {
    return client({
        method: 'GET', path: APIURLBASE + '?page=' + (page ? page : 0) + '&size=' + (size ? size : 20),
        headers: { 'Authorization': 'Bearer ' + token }
    });
}

function getAllCount(token) {
    return client({
        method: 'GET', path: APIURLBASE + '/count',
        headers: { 'Authorization': 'Bearer ' + token }
    });
}

function getById(token, id) {
    return client({
        method: 'GET', path: APIURLBASE + '/' + id,
        headers: { 'Authorization': 'Bearer ' + token }
    });
}

function create(token, inventory) {
    return client({
        method: 'POST', path: APIURLBASE, entity: inventory,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    });
}

function update(token, inventory) {
    return client({
        method: 'PUT', path: APIURLBASE, entity: inventory,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    });
}

function remove(token, inventory) {
    return client({
        method: 'DELETE', path: APIURLBASE + "/" + inventory.id,
        headers: { 'Authorization': 'Bearer ' + token }
    });
}
