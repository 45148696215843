import client from "../client"
import config from "../config.js";

const APIURLBASE = config.api.uri + "/users";

export const userService = {
    getAll,
    getById,
    update,
    remove,
    create
};

function getAll(token, page, size) {
    return client({
        method: 'GET', path: APIURLBASE + '?page=' + (page ? page : 0) + '&size=' + (size ? size : 20),
        headers: { 'Authorization': 'Bearer ' + token }
    });
}

function getById(token, id) {
    return client({
        method: 'GET', path: APIURLBASE + '/' + id,
        headers: { 'Authorization': 'Bearer ' + token }
    });
}

function create(token, user) {
    return client({
        method: 'POST', path: APIURLBASE, entity: user,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    });
}
function update(token, user) {
    return client({
        method: 'PUT', path: APIURLBASE, entity: user,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    });
}

function remove(token, user) {
    return client({
        method: 'DELETE', path: APIURLBASE + "/" + user.id,
        headers: {
            'Authorization': 'Bearer ' + token
        }
    });
}
