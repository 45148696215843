import React, { useState, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { Link, useLocation } from 'react-router-dom'

const Navbar = () => {
    const { authState, authService } = useOktaAuth();
    const [userInfo, setUserInfo] = useState(null);
    const login = () => authService.login('/');
    const logout = () => authService.logout();
    const pageLoc = new URLSearchParams(useLocation().search);

    useEffect(() => {
        console.log("navbar.useEffect - something changed")
        if (!authState.isAuthenticated) {
            // When user isn't authenticated, forget any user info
            setUserInfo(null);
        } else {
            authService.getUser().then(info => {
                //console.log("Navbar: useEffect setting user info=" + JSON.stringify(info));
                setUserInfo(info);
            });
        }
    }, [authState, authService]); // Update if authState changes        

    //console.log("navbar auth=" + JSON.stringify(authState));
    let auth = "";
    let userLabel = "";
    let titleLabel = "PrepNinja";

    if (authState.isPending) {
        auth =
            <Link class="nav-link" onClick={logout}>Loading...</Link>
    }
    else if (authState.isAuthenticated) {
        //console.log("Navbar: userInfo=" + userInfo);
        if (userInfo) {
            userLabel = <Link class="nav-link" to='/user'>
                <span class="icon mdi mdi-settings"></span>
                &nbsp;{userInfo.email}
            </Link>
        }
        auth =
            <Link class="nav-link" onClick={logout}>Logout</Link>

        if (location && location.pathname && location.pathname.startsWith('/dashboard')) {
            titleLabel = 'Dashboard';
        }
        else if (location && location.pathname && location.pathname.startsWith('/inventory')) {
            titleLabel = 'Inventory';
        }
        else if (location && location.pathname && location.pathname.startsWith('/user')) {
            titleLabel = 'Users';
        }
    }
    else {
        auth =
            <Link class="nav-link" onClick={login}>Login</Link>
    }

    return (
        <nav class="navbar navbar-expand fixed-top be-top-header">
            <div class="container-fluid">
                <div class="be-navbar-header"><a class="navbar-brand" href="index.html"></a></div>
                <div class="page-title"><span>{titleLabel}</span></div>
                <div class="be-right-navbar">
                    <ul class="nav navbar-nav float-right be-user-nav">
                        <div class="user-name">{userLabel}</div>
                        <div class="user-position online">{auth}</div>
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export { Navbar };
