import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

import SecureItemList from './List';
import AddEdit from './AddEdit';

function Item({ match }) {
    let query = new URLSearchParams(useLocation().search);
    const { path } = match;

    return (
        <Switch>
            <Route exact path={path} component={SecureItemList} />
            <Route path={`${path}/add`} component={AddEdit} />
            <Route path={`${path}/edit/:id`} component={AddEdit} />
        </Switch>
    );
}

export { Item };
