import client from "../client"
import config from "../config.js";

//const APIHOST = "https://api.nal.usda.gov";
//const APIURLBASE = APIHOST + "/fdc/v1/foods";
const APIURLBASE = config.api.uri + "/items";

export const foodApiService = {
    search,
    details
};

function search(token, query) {
    //https://api.nal.usda.gov/fdc/v1/foods/search?api_key=DEMO_KEY&query=progresso savory chicken
    return client({
        method: 'GET', path: APIURLBASE + '/lookup/' + query,
        headers: { 'Authorization': 'Bearer ' + token }
    });
}

function details(token, id) {
    //https://api.nal.usda.gov/fdc/v1/foods?api_key=DEMO_KEY&fdcIds=758498
    return client({
        method: 'GET', path: APIURLBASE + '/details/' + id,
        headers: { 'Authorization': 'Bearer ' + token }
    });
}
