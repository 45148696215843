import React, { useState, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { Link, useLocation } from 'react-router-dom'

const Navmenu = () => {
    const { authState, authService } = useOktaAuth();
    const [userInfo, setUserInfo] = useState(null);
    const login = () => authService.login('/');
    const logout = () => authService.logout('/');
    const pageLoc = new URLSearchParams(useLocation().search);

    useEffect(() => {
        console.log("navmenu.useEffect - something changed")
        if (!authState.isAuthenticated) {
            // When user isn't authenticated, forget any user info
            setUserInfo(null);
        } else {
            authService.getUser().then(info => {
                //console.log("Navmenu: useEffect setting user info=" + JSON.stringify(info));
                setUserInfo(info);
            });
        }
    }, [authState, authService]); // Update if authState changes        

    let auth = "";
    let userLabel = "";
    const linkState = []

    if (authState.isPending) {
        // auth is pending
    }
    else if (authState.isAuthenticated) {
        //console.log("Navmenu: userInfo=" + userInfo);
        //console.log("inv index location=" + JSON.stringify(location));
        if (userInfo) {
            // user is authenticated here

            linkState['food'] = '';
            linkState['location'] = '';
            linkState['static'] = '';

            if (location && location.pathname && location.pathname == ('/')) {
                linkState['static'] = 'active';
            }
            else if (location && location.pathname && location.pathname.startsWith('/dashboard')) {
                linkState['dashboard'] = 'active';
            }
            else if (location && location.pathname && location.pathname.startsWith('/item')) {
                linkState['item'] = 'active';
                linkState['food'] = 'active';
            }
            else if (location && location.pathname && location.pathname.startsWith('/user')) {
                linkState['user'] = 'active';
                linkState['user'] = 'active';
            }
        }
    }
    else {
        // user not logged in
    }
    
    return (
        <div class="be-left-sidebar">
            <div class="left-sidebar-wrapper">
                <div class="left-sidebar-spacer">
                    <div class="left-sidebar-scroll">
                        <div class="left-sidebar-content">
                            <ul class="sidebar-elements">
                                {authState.isAuthenticated && (
                                    <>
                                    <li class={(linkState['dashboard'] == 'active' ? 'active' : '')}>
                                        <Link class={'nav-link ' + linkState['food']} to='/dashboard'>Dashboard</Link>                                    
                                    </li>
                                    <li class={'parent ' + (linkState['food'] == 'active' ? 'active open' : '')}>
                                        <Link class={'nav-link ' + linkState['food']} to='/'>Food</Link>
                                        <ul class="sub-menu">
                                            <li class={linkState['item']}><Link class="nav-link" to='/item'>Items</Link></li>
                                        </ul>
                                    </li>
                                    <li class={'parent ' + (linkState['user'] == 'active' ? 'active open' : '')}>
                                        <Link class={'nav-link ' + linkState['user']} to='/'>User</Link>
                                        <ul class="sub-menu">
                                            <li class={linkState['user']}><Link class="nav-link" to='/user'>Users</Link></li>
                                        </ul>
                                    </li>
                                    </>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export { Navmenu };
